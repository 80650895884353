import React from "react";

import tw from "twin.macro";
import { ScienceTable } from "../common/ScienceTable";
import { MediaFeature } from "../sections/features/MediaFeature";
import { HowItWorks } from "../sections/howitworks";
import { TeacherTestimonials } from "../sections/testimonials/TestimonialSections";

import TeacherDashboardPNG from "../../images/teacherdashboardbig.png";
import { CardEditorFeature } from "../sections/features/CardEditorFeature";
import { ChromeFeature } from "../sections/features/ChromeFeature";
import HeroHeader from "../common/header/Hero";
import { CTAButton } from "../sections/header";
import { ImageClozeFeature } from "../sections/pricing/pricing";

const TrackingFeature: React.FC = () => (
  <MediaFeature
    caption={"🗒 Your students hate homework?"}
    header={
      <>
        <div className="flex flex-row">
          <span>Give your students homework</span>
        </div>
        <span className="text-purple-600">that's fun, effective, and easy</span>
        <br />
      </>
    }
    body={
      <>
        Zorbi eliminates the cognitive load of studying by telling students
        exactly when they need to study, what they need to study, and how long
        to study for.
        <br />
        <br />
        Zorbi study sessions take just 5-10 minutes per day and we&apos;ve been
        battle-tested in classrooms around the world.
      </>
    }
    disableMediaShadow={true}
    media={
      <div className="flex items-center w-full h-full">
        <img
          className="w-full max-w-lg mx-auto mt-auto rounded-lg sm:max-w-xl sm:mt-0 bg-plainGray-500 sm:p-1"
          src={TeacherDashboardPNG}
        ></img>
      </div>
    }
    mediaSide={"right"}
  />
);

export const TeacherPageContent: React.FC = () => {
  return (
    <>
      <section className="pt-12 bg-top bg-no-repeat ">
        <div className="container px-4 mx-auto">
          <div className="relative py-20 lg:p-20">
            <div className="mx-auto text-center ">
              <h1 className="mb-4 text-3xl font-bold text-transparent xs:text-4xl sm:text-5xl font-heading bg-clip-text bg-gradient-to-r from-blue-600 via-purple-500 to-red-400">
                Revolutionize the way you teach{" "}
                <br className="hidden lg:block" />
                <span>with science-backed learning</span>
              </h1>
              <p className="mb-8 text-base leading-relaxed sm:text-lg lg:text-xl text-blueGray-600">
                Zorbi makes it easy to integrate retrieval practice and
                distributed practice into your classroom <br />{" "}
                <br className="md:hidden" />
                If your student's grades don't improve, we'll give you a full
                refund.
              </p>
              <div className="flex flex-col items-center justify-center space-y-4">
                <CTAButton
                  className="self-center"
                  target="_blank"
                  href="https://buy.stripe.com/cN23cI7eL92j0364gw"
                >
                  Upgrade for $100/year
                </CTAButton>
              </div>
            </div>
          </div>
        </div>
        <div className="pb-16"></div>
      </section>

      <TeacherTestimonials />
      <div className="max-w-2xl px-4 mx-auto mt-8 mb-16">
        {/* <StudyingQuoteBlock /> */}
        <ScienceTable />
      </div>
      <HowItWorks />

      <div className="-mt-20">
        <TrackingFeature />
      </div>
      <div className="bg-blueGray-50">
        <CardEditorFeature />
      </div>
      <div>
        <ChromeFeature sideOverride="right" />
      </div>
      <div className="bg-blueGray-50">
        <ImageClozeFeature />
      </div>

      <section className="box-border relative block mb-20 overflow-hidden leading-6 text-left bg-gray-50">
        <div className="max-w-6xl px-4 mx-auto leading-6 text-left xl:px-12">
          <div className="box-border flex flex-col flex-wrap items-start mx-0 text-indigo-900">
            <div className="pb-4 text-sm font-bold text-left text-gray-700 uppercase">
              Loved by teachers around the world
            </div>
            <h3 className="relative w-full px-0 pb-2 m-0 font-sans text-2xl font-bold text-left xl:flex-grow-0 xl:flex-shrink-0 md:flex-grow-0 md:flex-shrink-0 lg:flex-grow-0 lg:flex-shrink-0">
              Powerful features that make your life easier.
            </h3>
          </div>
          <div className="grid grid-cols-3 gap-5 text-indigo-900 md:grid-cols-6">
            <div className="relative w-full col-span-3 px-4 leading-6 text-left">
              <div className="box-border flex items-center justify-start pt-12 text-indigo-900">
                <img
                  src="https://cdn.devdojo.com/tails/icons/telephone.png"
                  className="w-16 h-16 leading-6 text-left align-middle border-none"
                />
                <div className="pl-8 leading-6 text-left">
                  <div className="box-border pb-1 text-xl font-medium text-indigo-900">
                    Engaging study that works anywhere
                  </div>
                  <div className="box-border text-base leading-normal text-gray-700">
                    Zorbi works on every device including Chromebooks and iPads.
                    Students can start studying in class, and finish up on their
                    phones on their way home.
                  </div>
                </div>
              </div>
            </div>

            <div className="relative w-full col-span-3 px-4 leading-6 text-left">
              <div className="box-border flex items-center justify-start pt-12 text-indigo-900">
                <img
                  src="https://cdn.devdojo.com/tails/icons/tips.png"
                  className="w-16 h-16 leading-6 text-left align-middle border-none"
                />
                <div className="pl-8 leading-6 text-left">
                  <div className="box-border pb-1 text-xl font-medium text-indigo-900">
                    Identify struggling students in real-time
                  </div>
                  <div className="box-border text-base leading-normal text-gray-700">
                    Zorbi can predict exactly when and where your students need
                    help with their learning in real-time (all the way down to a
                    specific flashcard)
                  </div>
                </div>
              </div>
            </div>

            <div className="relative w-full col-span-3 px-4 leading-6 text-left">
              <div className="box-border flex items-center justify-start pt-12 text-indigo-900">
                <img
                  src="https://cdn.devdojo.com/tails/icons/mail-inbox-app.png"
                  className="w-16 h-16 leading-6 text-left align-middle border-none"
                />
                <div className="pl-8 leading-6 text-left">
                  <div className="box-border pb-1 text-xl font-medium text-indigo-900">
                    Get started in less than 10mins
                  </div>
                  <div className="box-border text-base leading-normal text-gray-700">
                    Create a class, give students an invite link, and they'll be
                    onboarded into Zorbi in minutes.
                  </div>
                </div>
              </div>
            </div>

            <div className="relative w-full col-span-3 px-4 leading-6 text-left">
              <div className="box-border flex items-center justify-start pt-12 text-indigo-900">
                <img
                  src="https://cdn.devdojo.com/tails/icons/clock.png"
                  className="w-16 h-16 leading-6 text-left align-middle border-none"
                />
                <div className="pl-8 leading-6 text-left">
                  <div className="box-border pb-1 text-xl font-medium text-indigo-900">
                    Engaging and effective homework
                  </div>
                  <div className="box-border text-base leading-normal text-gray-700">
                    Set and track engaging homework that improves student study
                    habits using: <strong>self-testing</strong> and{" "}
                    <strong>spaced-studying</strong>.
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="relative w-full col-span-3 px-4 leading-6 text-left">
              <div className="box-border flex items-center justify-start pt-12 text-indigo-900">
                <img
                  src="https://cdn.devdojo.com/tails/icons/calendar.png"
                  className="w-16 h-16 leading-6 text-left align-middle border-none"
                />
                <div className="pl-8 leading-6 text-left">
                  <div className="box-border pb-1 text-xl font-medium text-indigo-900">
                    Schedulers
                  </div>
                  <div className="box-border text-base leading-normal text-gray-700">
                    Schedule your automations and jobs
                  </div>
                </div>
              </div>
            </div>

            <div className="relative w-full col-span-3 px-4 leading-6 text-left">
              <div className="box-border flex items-center justify-start pt-12 text-indigo-900">
                <img
                  src="https://cdn.devdojo.com/tails/icons/bank.png"
                  className="w-16 h-16 leading-6 text-left align-middle border-none"
                />
                <div className="pl-8 leading-6 text-left">
                  <div className="box-border pb-1 text-xl font-medium text-indigo-900">
                    Simple Billing
                  </div>
                  <div className="box-border text-base leading-normal text-gray-700">
                    Simple and easy billing system
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
};
