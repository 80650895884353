import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import tw from "twin.macro";

const roundingCSS =
  "first:rounded-tl-xl first:rounded-bl-xl last:rounded-tr-xl last:rounded-br-xl";

const TableHeader = tw.th`px-6 py-3 even:hidden md:even:block text-base font-semibold text-white ${roundingCSS}`;

const TableSectionHeader = tw.th`px-6 rounded-2xl bg-purple-200 py-3 w-full font-semibold text-gray-700 ${roundingCSS}`;
const TableBodySection = tw.tbody`p-4 rounded-2xl`;
const TableRow = tw.tr`rounded-full bg-gray-600 text-white`;
const TableData = tw.td`p-4 ${roundingCSS} even:hidden md:even:block`;

interface LearningTechnique {
  technique: string;
  description: string;
  utility: "high" | "moderate" | "low";
}

const ZorbiLearningTechniques: LearningTechnique[] = [
  {
    technique: "Practice Testing\n(active recall)",
    description:
      "Testing yourself using questions (e.g. flashcards, practice tests)",
    utility: "high",
  },

  {
    technique: "Distributed Practice\n(spaced repetition)",
    description:
      "The act of spacing out repeated 'practice testing' across days or weeks.",
    utility: "high",
  },

  {
    technique: "Interleaved Practice",
    description:
      "Mixing different kinds of problems or materials in a single study session.",
    utility: "moderate",
  },
  {
    technique: "Self-Explanation",
    description:
      "Explaining how new info you learn is related to existing knowledge, or explaining the steps you took during problem solving.",
    utility: "moderate",
  },

  {
    technique: "Elaborative Interrogation",
    description: "Explaining why a specific concept or fact is true.",
    utility: "moderate",
  },
];
const StudentLearningTechniques: LearningTechnique[] = [
  {
    technique: "Highlighting / Underlining",
    description: "Marking important sections while reading text.",
    utility: "low",
  },

  {
    technique: "Rereading/Rewatching",
    description: "Restudying the same material over and over.",
    utility: "low",
  },

  {
    technique: "Summarization",
    description: "Summarizing text or videos that you're trying to learn.",
    utility: "low",
  },
];

const Pill = tw.p`rounded-2xl py-2 px-4 flex flex-row items-center align-middle justify-center w-full text-center font-semibold`;
const HighPill = tw(Pill)`bg-green-500`;
const ModeratePill = tw(Pill)`bg-yellow-500`;
const LowPill = tw(Pill)`bg-red-500`;

const UtilityPill: React.FC<{ value: "high" | "moderate" | "low" }> = ({
  value,
}) => {
  switch (value) {
    case "high":
      return <HighPill>High</HighPill>;
    case "moderate":
      return <ModeratePill>Moderate</ModeratePill>;
    case "low":
      return <LowPill>Low</LowPill>;
  }
};

export const ScienceTable: React.FC = () => {
  return (
    <div className="flex flex-col items-center justify-center w-full">
      <table
        className="table space-y-6 border-separate"
        style={{ borderSpacing: "0px 12px" }}
      >
        <thead className="my-2 bg-gray-50">
          <TableRow className="my-2 rounded-full">
            <TableHeader scope="col">Technique</TableHeader>
            <TableHeader scope="col">Description</TableHeader>

            <TableHeader scope="col">Effectiveness</TableHeader>
          </TableRow>
        </thead>
        <TableBodySection>
          <TableRow>
            <TableSectionHeader colSpan={3}>
              <div className="flex flex-row items-center justify-center w-full space-x-2">
                <p className="flex flex-shrink">The techniques behind</p>{" "}
                <StaticImage
                  style={{ filter: "hue-rotate:(180deg)" }}
                  src={"../../../images/zorbilogo_text.png"}
                  alt="Zorbi"
                  width={130}
                  className="inline-block"
                  placeholder="none"
                />
              </div>
            </TableSectionHeader>
          </TableRow>
          {ZorbiLearningTechniques.map((item, ind) => (
            <TableRow key={ind}>
              <TableData>{item.technique}</TableData>

              <TableData>{item.description}</TableData>

              <TableData className="p-0">
                <UtilityPill value={item.utility} />
              </TableData>
            </TableRow>
          ))}
        </TableBodySection>
        <TableBodySection>
          <TableRow>
            <TableSectionHeader colSpan={3}>
              What students are using right now
            </TableSectionHeader>
          </TableRow>
          {StudentLearningTechniques.map((item, ind) => (
            <TableRow key={ind}>
              <TableData>{item.technique}</TableData>

              <TableData>{item.description}</TableData>

              <TableData className="pr-2">
                <UtilityPill value={item.utility} />
              </TableData>
            </TableRow>
          ))}
        </TableBodySection>
      </table>
      <p className="text-xs italic font-medium">
        Adapted from "Improving students’ learning with effective learning
        techniques: Promising directions from cognitive and educational
        psychology.” (Dunlosky, John, et al. 2013)
      </p>
    </div>
  );
};
