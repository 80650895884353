import React from "react";
import SEO from "../components/common/layout/seo";
import Layout from "../components/common/layout/layout";
import { Navigation } from "../components/common/navigation/navigation";

import { Footer } from "../components/sections/footer";

import { TeacherPageContent } from "../components/pages/TeacherPageContent";
import { teachersPage } from "../utils/seoText";

const TeachersPage = () => {
  return (
    <Layout>
      <SEO {...teachersPage} />
      <Navigation />
      <TeacherPageContent />
      <Footer />
    </Layout>
  );
};

export default TeachersPage;
